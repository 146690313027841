import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentResponse, TemplateResponse } from "@gpt3/types";
import clsx from "clsx";
import React from "react";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import styles from "./FavouriteButton.module.scss";

interface Props {
  data: ContentResponse;
  onClick: (t: ContentResponse, e) => void;
}

const FavouriteButton: React.FC<Props> = (props: Props) => {
  const { data, onClick } = props;
  return (
    <ActionButton
      label="Add to Favourites"
      className={clsx({ [styles.favourite]: data.isFavorite })}
      onClick={(e) => onClick(data, e)}
    >
      <FontAwesomeIcon icon={faStar} title={"Add to Favourites"} />
    </ActionButton>
  );
};

export default FavouriteButton;
