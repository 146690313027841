import {
  AutoCompleteRequest,
  ContentResponse,
  EditContentRequest,
  GenerateContentRequest,
  GenerateEmailRequest,
  GenerateFollowupEmailRequest,
  GenerateIntroEmailsRequest,
  GenerateSubjectLineRequest,
  GetContentRequest,
  GetTemplateRequest,
  LinkedInMessageRequest,
  Platform,
  TagsResponse,
  TemplateResponse,
  TextActionResponse,
} from "@gpt3/types";
import { ApiClientOptions, HttpClient } from "./http-client";

export class ApiClient extends HttpClient {
  constructor(options: ApiClientOptions) {
    super(options);
  }

  getTags(): Promise<TagsResponse> {
    return this.get("/tags");
  }

  getTemplates(params?: GetTemplateRequest): Promise<TemplateResponse[]> {
    return this.get("/templates", params);
  }

  getTemplateById(id: string): Promise<TemplateResponse> {
    return this.get(`/templates/${id}`);
  }

  revertContentToOriginal(contentId: string): Promise<ContentResponse> {
    return this.patch(`/content/${contentId}/revertToOriginal`);
  }

  favouriteContent(contentId: string): Promise<ContentResponse> {
    return this.patch(`/content/${contentId}/favorite`);
  }

  deleteContent(contentId: string): Promise<ContentResponse> {
    return this.delete(`/content/${contentId}`);
  }

  undoDeleteContent(contentId: string): Promise<ContentResponse[]> {
    return this.patch(`/content/${contentId}/delete/undo`);
  }

  flagContent(contentId: string, message: string): Promise<ContentResponse> {
    return this.patch(`/content/${contentId}/flag`, { message });
  }

  generateContent(params: GenerateContentRequest): Promise<ContentResponse[]> {
    return this.post("/content", params);
  }

  editContent(
    contentId: string,
    params: EditContentRequest
  ): Promise<ContentResponse> {
    return this.patch(`/content/${contentId}`, params);
  }

  addTagToContent(contentId: string, tag: string): Promise<TemplateResponse> {
    return this.patch(`/content/${contentId}/addTag`, { tag });
  }

  removeTagFromContent(
    contentId: string,
    tag: string
  ): Promise<TemplateResponse> {
    return this.patch(`/content/${contentId}/removeTag`, { tag });
  }

  getContent(params: GetContentRequest): Promise<ContentResponse[]> {
    return this.get("/content", params);
  }

  rephraseContent(text: string): Promise<TextActionResponse[]> {
    return this.post("/content/actions/rephrase", { text });
  }

  shortenContent(text: string): Promise<TextActionResponse[]> {
    return this.post("/content/actions/shorten", { text });
  }

  lengthenContent(text: string): Promise<TextActionResponse[]> {
    return this.post("/content/actions/lengthen", { text });
  }

  autocomplete(body: AutoCompleteRequest): Promise<TextActionResponse[]> {
    return this.post("/content/actions/autocomplete", body);
  }

  linkedinMessage(body: LinkedInMessageRequest): Promise<TextActionResponse[]> {
    return this.post("/content/linkedin/message", body);
  }

  generateIntroEmails(body: GenerateIntroEmailsRequest): Promise<string[]> {
    return this.post("/emails/intro", body);
  }

  generateReferralEmails(body: GenerateEmailRequest): Promise<string[]> {
    return this.post("/emails/referral", body);
  }

  generateFollowupEmails(
    body: GenerateFollowupEmailRequest
  ): Promise<string[]> {
    return this.post("/emails/followup", body);
  }

  generateMultipleChoiceEmails(body: GenerateEmailRequest): Promise<string[]> {
    return this.post("/emails/multiple-choice", body);
  }

  generateBreakupEmails(body: GenerateEmailRequest): Promise<string[]> {
    return this.post("/emails/breakup", body);
  }

  generateSubjectLine(
    body: GenerateSubjectLineRequest
  ): Promise<{ subject: string }> {
    return this.post("/emails/subject", body);
  }
}
