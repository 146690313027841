import React, { forwardRef } from "react";
import cx from "clsx";
import _ from "lodash";
import clsx from "clsx";
import { ContentResponse } from "@gpt3/types";
import { Actions } from "../../hooks/useContentActions";
import { useForm } from "react-hook-form";
import { Dialog, Menu } from "@headlessui/react";
import styles from "./FlagItem.module.scss";
import modalStyles from "../../atoms/Modal/Modal.module.scss";
import Button from "../../atoms/Button";
import { client } from "../../api";
import toast from "react-hot-toast";
import { Size, Variant } from "../../atoms/Button/Button";

type Props = {
  data?: ContentResponse;
  isOpen: boolean;
  onClose: any;
};
type ContentEditTextAreaTypes = {
  id: ContentResponse["id"];
  action: Actions["editContent"];
} & React.ButtonHTMLAttributes<HTMLTextAreaElement>;

const FlagItem: React.FC<Props> = (props) => {
  const { data, isOpen, onClose } = props;
  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      content: "",
    },
  });
  const onSubmit = async (formData) => {
    await client.flagContent(data.id, formData["content"]);
    toast.success("Feedback submitted successfully");
    onClose();
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={cx(modalStyles.modal, styles.modal)}
    >
      <div className={modalStyles.modalContentContainer}>
        <Dialog.Overlay className={modalStyles.modalOverlay} />

        <div className={clsx(modalStyles.modalContent, modalStyles.smallModal)}>
          {/* ... */}
          <div className={styles.menuBar}>
            <div className={styles.title}>{"Flag this Content"}</div>
          </div>
          <div className={styles.subText}>
            Help improve our generated content by flagging low quality outputs.
          </div>
          <div className={styles.textContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <textarea rows={8} id={data.id} {...register("content")} />
              <div className={styles.buttonContainer}>
                <Button
                  size={Size.LARGE}
                  variant={Variant.CANCEL}
                  type="reset"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button size={Size.LARGE} type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FlagItem;
