import { GenerateContentRequest } from '@gpt3/types';
import React, { useMemo } from 'react';
import _ from 'lodash';
import useSWR from 'swr';
import { client } from "../api";
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { ContentResponse } from '@gpt3/types';
import { toast } from 'react-hot-toast';

const Output = atom<ContentResponse[]>({
  key: 'currentOutput',
  default: [],
});
const LoadingOutput = atom<boolean>({
  key: 'outputLoading',
  default: false,
});

const useMakeOutput = () => {
  const [output, setOutput] = useRecoilState(Output);
  const [loading, setLoading] = useRecoilState(LoadingOutput);
  return async (input: GenerateContentRequest) => {
    setLoading(true);
    let newData: ContentResponse[];
    try {
      newData = await client.generateContent(input);
      newData.forEach((a) => (a.isNew = true));
      if (!newData.length) {
        toast.error("Please try modifying the inputs");
      }
      const updatedOutput = output.map((a) =>{ return {...a, isNew:false}});
      setOutput([...newData, ...updatedOutput]);
      toast.success("Content generated successfully");
    } catch (error) {
      toast.error("Something went wrong. Please try again");
    }
    setLoading(false);
  };
};

const useOutput = () => {
  return useRecoilState(Output);
};
const useIsGenerating = () => {
  return useRecoilValue(LoadingOutput);
};
export { useMakeOutput, useOutput, useIsGenerating };
