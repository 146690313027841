import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import styles from "./Loader.module.scss";
import loaderGif from "../../assets/regieLoader.gif";

type Props = {
  className?: string;
};

const Loader: React.FC<Props> = (props: Props) => {
  const { className, ...rest } = props;
  return (
    <div className={styles.loaderContainer}>
      <LoaderIcon className={className} />
    </div>
  );
};

const LoaderIcon: React.FC<Props> = (props: Props) => {
  const { className, ...rest } = props;
  return (
    <img src={loaderGif} className={clsx(className, styles.loader)} {...rest} />
  );
};

export default Loader;
export { LoaderIcon };