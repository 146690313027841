import React, { ButtonHTMLAttributes, ReactNode } from "react";
import cx from "clsx";
import styles from "./Button.module.scss";

enum Variant {
  PRIMARY,
  CANCEL,
}
enum Size {
  LARGE,
  SMALL,
  DEFAULT,
}
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  children?: ReactNode;
  size?: Size;
  isLoading?: boolean;
}

const SIZE_MAPS: Record<Size, string> = {
  [Size.SMALL]: styles.small,
  [Size.DEFAULT]: styles.default,
  [Size.LARGE]: styles.large,
};

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PRIMARY]: styles.primary,
  [Variant.CANCEL]: styles.cancel,
};

const Button: React.FC<Props> = (props: Props) => {
  const {
    children,
    className,
    variant = Variant.PRIMARY,
    size = Size.DEFAULT,
    isLoading = false,
    ...rest
  } = props;
  return (
    <button
      className={cx(
        styles.button,
        VARIANT_MAPS[variant],
        SIZE_MAPS[size],
        className,
        {
          [styles.isLoading]: isLoading,
        }
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export { Variant, Size };
export default Button;
