import axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import axiosStackTrace from "axios-better-stacktrace";

export type ErrorHandler = (error: unknown) => Promise<unknown>;

const defaultErrorHandler: ErrorHandler = async (_: unknown) => {
  return;
};

const DEFAULT_TIMEOUT = 30000;

export type ApiClientOptions = {
  baseUrl: string;
  errorHandler?: ErrorHandler;
  timeout?: number;
  getAccessToken: () => string;
};

export class HttpClient {
  private client: AxiosInstance;
  private errorHandler: ErrorHandler;

  constructor(options: ApiClientOptions) {
    this.client = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: options.timeout || DEFAULT_TIMEOUT,
      baseURL: options.baseUrl,
    });
    axiosStackTrace(this.client);
    this.setErrorHandler(options.errorHandler);
    this.client.interceptors.request.use(function (config) {
      const token = options.getAccessToken();
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (axios.isCancel(error)) {
          return {};
        }
        await this.errorHandler(error);
        return Promise.reject(error.response.data);
      }
    );
  }

  setErrorHandler(errorHandler: ErrorHandler = defaultErrorHandler) {
    this.errorHandler = errorHandler;
  }

  setHeader(name: string, val?: string) {
    if (val) {
      this.client.defaults.headers[name] = val;
    } else {
      delete this.client.defaults.headers[name];
    }
  }

  async makeRequest<T>(
    path: string,
    method: Method,
    config: Partial<AxiosRequestConfig>
  ): Promise<T> {
    const response = await this.client.request({
      method,
      url: path,
      ...config,
    });
    return response.data.data;
  }

  get<T>(
    path: string,
    params?: unknown,
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "GET", { ...config, params });
  }

  delete<T>(
    path: string,
    data?: unknown,
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "DELETE", { ...config, data });
  }

  head<T>(
    path: string,
    params: unknown,
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "HEAD", { ...config, params });
  }

  post<T>(
    path: string,
    data: unknown = {},
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "POST", { ...config, data });
  }

  put<T>(
    path: string,
    data: unknown = {},
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "PUT", { ...config, data });
  }

  patch<T>(
    path: string,
    data: unknown = {},
    config: Partial<AxiosRequestConfig> = {}
  ): Promise<T> {
    return this.makeRequest(path, "PATCH", { ...config, data });
  }
}
