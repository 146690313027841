import _ from "lodash";
import React from "react";
import { faTag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTagsInput from "react-tagsinput";
import AutoSuggest from "react-autosuggest";
import { useTags } from "../../hooks/useTags";
import "react-tagsinput/react-tagsinput.css";
import styles from "./TagsInput.module.scss";

interface Props {
  addTag: (tag: string) => void;
  removeTag: (index: number) => void;
  tags: string[];
}
function autosuggestRenderInput(props) {
  const { addTag, tags, ...rest } = props;
  const handleOnChange = (e, { newValue, method }) => {
    if (method === "enter") {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  };
  const handleKeyDown = (e) => {
    props?.onKeyDown(e);
    if (e.key === "Enter") {
      addTag(e.target.value);
    }
  };
  const inputValue = (props.value && props?.value?.trim().toLowerCase()) || "";
  const inputLength = inputValue?.length || 0;

  let suggestions = inputLength
    ? _.filter(tags, (t) => {
        return _.includes(t.toLowerCase(), inputValue);
      })
    : Array.isArray(tags)
    ? tags
    : [];

  return (
    <AutoSuggest
      // containerProps={{
      //   className: "inline",
      // }}
      theme={{
        container: styles.autoSuggest,
        suggestionsContainerOpen: styles.suggestionsContainerOpen,
        suggestion: styles.suggestion,
      }}
      ref={props.ref}
      suggestions={suggestions}
      getSuggestionValue={(suggestion) => suggestion}
      shouldRenderSuggestions={() => true}
      renderSuggestion={(suggestion) => <span>{suggestion}</span>}
      inputProps={{
        ...rest,
        onChange: handleOnChange,
        value: inputValue,
        onKeyDown: handleKeyDown,
      }}
      onSuggestionSelected={(e, { suggestion }) => {
        addTag(suggestion);
      }}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={() => {}}
    />
  );
}

function defaultRenderLayout(tagComponents, inputComponent) {
  return (
    <>
      <span className={styles.tagIcon}>
        <FontAwesomeIcon icon={faTag} />
      </span>
      {tagComponents}
      {inputComponent}
    </>
  );
}
const TagsInput: React.FC<Props> = (props: Props) => {
  const { addTag, removeTag, tags } = props;
  const { data: allTags, error } = useTags();
  return (
    <ReactTagsInput
      renderLayout={defaultRenderLayout}
      onChange={() => {}}
      inputProps={{
        className: styles.input,
        addTag,
      }}
      renderInput={(props) =>
        autosuggestRenderInput({
          ...props,
          tags: _.filter(allTags?.tags, (t) => !_.includes(tags, t)),
        })
      }
      tagProps={{
        onRemove: removeTag,
      }}
      focusedClassName={styles.focussed}
      className={styles.tagsInput}
      value={tags ?? []}
      renderTag={(props) => {
        let {
          tag,
          key,
          disabled,
          onRemove,
          classNameRemove,
          getTagDisplayValue,
          ...other
        } = props;
        return (
          <span key={key} {...other} className={styles.tag}>
            {getTagDisplayValue(tag)}
            {!disabled && (
              <a className={styles.remove} onClick={(e) => onRemove(key)}>
                {<FontAwesomeIcon icon={faTimes} />}
              </a>
            )}
          </span>
        );
      }}
      onlyUnique
    />
  );
};

export default TagsInput;
