import { TemplateField, TemplateType } from '@gpt3/types';
import _ from 'lodash';
import React from 'react';
import { FormState, useForm, UseFormReset } from 'react-hook-form';
import Button from '../../atoms/Button';
import { Variant } from '../../atoms/Button/Button';
import Field from '../../atoms/Field/Field';
import { useContentHistory } from '../../hooks/useContentHistory';
import { useIsGenerating, useMakeOutput } from '../../hooks/useOutput';
import { useTemplate } from '../../hooks/useTemplates';
import styles from './TemplateForm.module.scss';

type Props = {
  fields: TemplateField[];
  defaultValues?: Record<string, string>;
  onSubmit: (...args) => {};
};

type TemplateFooterProps = {
  formState: FormState<Record<string, string>>;
  reset: UseFormReset<Record<string, string>>;
};

const TemplateFooter: React.FC<TemplateFooterProps> = (props) => {
  const { reset, formState } = props;
  const isLoading = useIsGenerating();
  return (
    <div className={styles.footer}>
      <Button
        onClick={() => {
          reset({});
        }}
        variant={Variant.CANCEL}
        type={'reset'}
      >
        Clear Inputs
      </Button>
      <Button
        type="submit"
        disabled={isLoading || !formState.isValid}
        isLoading={isLoading}
      >
        Generate AI Content
      </Button>
    </div>
  );
};

const TemplateFormStateLess: React.FC<Props> = (props) => {
  const { fields, defaultValues, onSubmit } = props;
  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: _.pick(
      defaultValues,
      fields.map((a) => a.key),
    ),
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.fields}>
        {fields.map((field) => (
          <Field
            fieldType={field.fieldType}
            containerClassName={styles.field}
            maxLength={field.characterLimit}
            placeholder={field.placeholder}
            label={field.title}
            required={field.required}
            defaultValue={_.get(defaultValues, field.key, '')}
            {...register(field.key, {
              required: !!field.required,
              maxLength: field.characterLimit,
            })}
          />
        ))}
      </div>
      <TemplateFooter reset={reset} formState={formState} />
    </form>
  );
};

const TemplateForm: React.FC = () => {
  const { data, error } = useTemplate();
  const { data: contentHistory } = useContentHistory(data?.id);
  const handleSubmit = useMakeOutput();

  if (!data || error || !contentHistory) {
    return <></>;
  }
  return (
    <TemplateFormStateLess
      key={data.id}
      fields={data.fields}
      defaultValues={Array.isArray(contentHistory) && contentHistory[0]?.fields}
      onSubmit={(x) =>
        handleSubmit({ templateId: data.id as TemplateType, fields: x })
      }
    />
  );
};

export { TemplateFormStateLess };

export default TemplateForm;
