import { atom, useRecoilState, useRecoilValue } from "recoil";
import qs from "query-string";
import React, { useContext, useMemo } from "react";
import useSWR, { useSWRInfinite } from "swr";
import { client } from "../api";
import _ from "lodash";
import { useLocationState, useSearchState } from "./useSearch";
import { useParams } from "react-router-dom";
import { PlatformContext } from "./usePlatformContext";

const useContentHistory = (templateId?: string) => {
  return useSWR(`/history/${templateId ?? ""}`, () =>
    client.getContent({ templateId })
  );
};

const LIMIT = 50;

const FILTER_PARAMS = {
  FAVORITE: "favorite",
  TEMPLATE_ID: "tempate_id",
  DELETED: "deleted",
};

type DefaultFilters = {
  favorite?: boolean;
  deleted?: boolean;
};
const useFilters = () => {
  const platform = useContext(PlatformContext)
  const defaultParams = useFilterPathParams();
  const { favorite, deleted } = defaultParams || {};
  const searchString = useSearchState();
  const favoriteFilter = useLocationState(FILTER_PARAMS.FAVORITE);
  const templateIdFilter = useLocationState(FILTER_PARAMS.TEMPLATE_ID);
  const deletedFilter = useLocationState(FILTER_PARAMS.DELETED);
  return {
    ...((favoriteFilter.text || favorite) && {
      favorite: Boolean(favoriteFilter.text) || favorite,
    }),
    ...(templateIdFilter.text && { templateId: templateIdFilter.text }),
    ...(searchString.text && { searchQuery: searchString.text }),
    ...((deletedFilter.text || deleted) && {
      deleted: Boolean(deletedFilter.text) || deleted,
    }),
    ...(platform && {platform})
  };
};

type PaginationParams = {
  pageNo: number;
};
const pagination = atom<PaginationParams>({
  key: "pagination",
  default: {
    pageNo: 0,
  },
});

const usePagination = () => {
  const [paginationData, setPaginationData] = useRecoilState(pagination);
  const onPrevClick = () => {
    if (paginationData.pageNo === 0) {
      return;
    }
    setPaginationData({ pageNo: paginationData.pageNo - 1 });
    console.log("Prev clicked");
  };
  const onNextClick = () => {
    setPaginationData({ pageNo: paginationData.pageNo + 1 });
  };
  const resetPagination = () => {
    setPaginationData({ pageNo: 0 });
  };
  return {
    paginationData,
    onPrevClick,
    onNextClick,
    resetPagination,
  };
};

const getPaginationParams = (pageNo: number = 0) => {
  return {
    offset: pageNo * LIMIT,
    limit: LIMIT,
  };
};

const useFilterPathParams = () => {
  const params: any = useParams();
  return useMemo(() => {
    if (params.filter) {
      if (params.filter === FILTER_PARAMS.FAVORITE) {
        return {
          favorite: true,
        };
      }
      if (params.filter === FILTER_PARAMS.DELETED) {
        return {
          deleted: true,
        };
      }
    }
  }, [params]);
};
const useFilteredHistory = () => {
  const filters = useFilters();
  const paginationData = useRecoilValue(pagination);
  const getParams = () => {
    const paginationParams = getPaginationParams(paginationData.pageNo);
    return { ...filters, ...paginationParams };
  };
  const queryString = qs.stringify(getParams());
  return useSWR(queryString, () => {
    return client.getContent(getParams());
  });
};

export {
  useFilterPathParams,
  useContentHistory,
  FILTER_PARAMS,
  useFilters,
  useFilteredHistory,
  usePagination,
};
