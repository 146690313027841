import React, { ChangeEventHandler } from "react";
import cx from "clsx";
import { ReactHTMLElement } from "react";
import { useSearchState } from "../../hooks";
import _ from "lodash";
import styles from "./SearchBar.module.scss";

type Props = {
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SearchBarStateless: React.FC<Props> = (props: Props) => {
  const {
    placeholder = "Search",
    className,
    containerClassName,
    ...rest
  } = props;
  return (
    <div className={cx(containerClassName, styles.searchBar)}>
      <input
        className={cx(className, styles.input)}
        placeholder={placeholder}
        {...rest}
      ></input>
    </div>
  );
};

const SearchBar: React.FC<Props> = (props) => {
  const { text, setText } = useSearchState();
  const { placeholder = "Search", ...rest } = props;
  return (
    <SearchBarStateless
      onChange={_.debounce((e) => setText(e.target.value), 300)}
      defaultValue={text}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export { SearchBar, SearchBarStateless };
