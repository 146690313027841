import React, { forwardRef, useState } from "react";
import cx from "clsx";
import { ContentResponse } from "@gpt3/types";
import _ from "lodash";
import { faEllipsisH, faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTextToShow } from "../../utils";
import toast from "react-hot-toast";
import { Actions, useDeleteContent } from "../../hooks/useContentActions";
import { useTemplates } from "../../hooks/useTemplates";
import { useForm } from "react-hook-form";
import { Dialog, Menu } from "@headlessui/react";
import styles from "./GeneratedContentModal.module.scss";
import dropdownStyles from "../../atoms/Dropdown/Dropdown.module.scss";
import modalStyles from "../../atoms/Modal/Modal.module.scss";
import { CopyButton, FavouriteButton } from "../Buttons";
import clsx from "clsx";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import { TemplateResponse } from "@gpt3/types";

type Props = {
  data?: ContentResponse;
  actions: Actions;
  isOpen: boolean;
  onClose: any;
};
type ContentEditTextAreaTypes = {
  id: ContentResponse["id"];
  action: Actions["editContent"];
} & React.ButtonHTMLAttributes<HTMLTextAreaElement>;

const getInputKeyPrettyName = (key, template: TemplateResponse) => {
  const field = _.find(template.fields, (f) => f.key === key);
  if (field && field.title) {
    return field.title;
  }
  return key;
};

const ContentEditTextArea: React.ForwardRefExoticComponent<ContentEditTextAreaTypes> = forwardRef<
  any,
  ContentEditTextAreaTypes
>((props, ref) => {
  const { id, className, onChange, action, ...rest } = props;
  const handleChange = _.debounce(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      action(id, e.target.value);
      onChange(e);
    },
    500
  );

  return (
    <textarea
      ref={ref}
      className={styles.textArea}
      rows={15}
      onChange={handleChange}
      {...rest}
    />
  );
});

const GeneratedContentModal: React.FC<Props> = (props) => {
  const { data, actions, isOpen, onClose } = props;
  const { data: templates } = useTemplates();
  const [showInputs, setShowInputs] = useState(false);
  const { text, isEdited } = getTextToShow(data);
  const {
    isDeleted,
    onDeleteClick,
    onUndoClick,
    handleDelete,
  } = useDeleteContent(data, actions);
  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      content: text,
    },
  });
  const {
    toggleFavourite,
    addTag,
    removeTag,
    editContent,
    revertContentToOriginal,
  } = actions;
  const handleRevertToOriginal = () => {
    reset({ content: data.openAIText });
    revertContentToOriginal(data.id);
  };
  const handleClose = () => {
    onClose();
    handleDelete();
  };
  if (!data || !templates) {
    return <></>;
  }
  const template = _.find(templates, (t) => t.id === data.templateId);
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={cx(modalStyles.modal, styles.modal)}
    >
      <div className={modalStyles.modalContentContainer}>
        <Dialog.Overlay className={modalStyles.modalOverlay} />

        <div className={modalStyles.modalContent}>
          {/* ... */}
          <div className={styles.menuBar}>
            <div className={styles.title}>{template.title}</div>
            <div className={styles.actionGroup}>
              {!isDeleted ? (
                <>
                  <FavouriteButton
                    data={data}
                    onClick={(t) => actions.toggleFavourite(t.id)}
                  />
                  <CopyButton
                    text={text}
                    onCopy={() => toast.success("Copied")}
                  />
                </>
              ) : (
                <>
                  <ActionButton label={"Undo"} onClick={onUndoClick}>
                    <FontAwesomeIcon icon={faUndo} title={"Undo"} />
                  </ActionButton>
                </>
              )}
              <Menu as="div" className={dropdownStyles.menu}>
                {({ open }) => (
                  <>
                    <Menu.Button
                      className={clsx(styles.menuButton, {
                        [styles.open]: open,
                      })}
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </Menu.Button>
                    <Menu.Items className={dropdownStyles.menuItems}>
                      <div>
                        <Menu.Item
                          as="button"
                          onClick={() => {
                            setShowInputs(!showInputs);
                          }}
                          className={dropdownStyles.item}
                        >
                          {showInputs ? "Hide Inputs" : "Show Inputs"}
                        </Menu.Item>
                        <Menu.Item
                          as="button"
                          onClick={() => {
                            isDeleted ? onUndoClick() : onDeleteClick();
                          }}
                          className={clsx(
                            dropdownStyles.item,
                            dropdownStyles.delete
                          )}
                        >
                          {isDeleted ? "Undo Delete" : "Move to Trash"}
                        </Menu.Item>
                        {isEdited && (
                          <Menu.Item
                            as="button"
                            onClick={handleRevertToOriginal}
                            className={dropdownStyles.item}
                          >
                            Revert to original
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </div>
          </div>
          {showInputs && (
            <div className={styles.inputContainer}>
              {_.map(data.fields, (value, key) => {
                return (
                  <div className={styles.inputs} key={key}>
                    <div className={styles.key}>
                      {getInputKeyPrettyName(key, template)}
                    </div>
                    {value && (
                      <div className={styles.value}>
                        <div className={styles.text}>{value}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.textContainer}>
            <form>
              <ContentEditTextArea
                id={data.id}
                action={editContent}
                {...register("content")}
              />
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GeneratedContentModal;
