import { faFlag, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentResponse, TemplateResponse } from "@gpt3/types";
import clsx from "clsx";
import React from "react";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import styles from "./FavouriteButton.module.scss";

interface Props {
  data: ContentResponse;
  onClick: () => void;
}

const FlagButton: React.FC<Props> = (props: Props) => {
  const { data, onClick } = props;
  return (
    <ActionButton label="Flag Content" onClick={(e) => onClick()}>
      <FontAwesomeIcon icon={faFlag} title={"Flag Content"} />
    </ActionButton>
  );
};

export default FlagButton;
