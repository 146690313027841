import React, { useEffect } from "react";
import { ContentResponse } from "@gpt3/types";
import _ from "lodash";
import GeneratedContentItem from "../GeneratedContentItem/GeneratedContentItem";
import {
  ContentWithActions,
  useContentMutations,
} from "../../hooks/useContentActions";
import GeneratedContentModal from "../GeneratedContentModal/GeneratedContentModal";
import { atom, useRecoilState } from "recoil";
import { useEditModalState } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
import styles from "./ContentTab.module.scss";
import { useRef } from "react";
import { useFlagModalState } from "../../hooks/useFlagModal";
import FlagItem from "../FlagModal/FlagItemModal";

type Props = ContentWithActions & { scrollToTop?: () => void };

function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ContentTab: React.FC<Props> = (props) => {
  const { data, mutation, scrollToTop } = props;
  const actions = useContentMutations(data, mutation);
  const [modalData, setModalData] = useEditModalState();
  const [flagModalData, setFlagModalData] = useFlagModalState();
  const handleItemClick = (id) => {
    setModalData({ isOpen: true, id });
  };
  const handleFlagModalOpen = (id) => {
    setFlagModalData({ isOpen: true, id });
  };
  const prevData = usePrevious(data);
  useEffect(() => {
    if (data.length > prevData?.length) scrollToTop();
  }, [data.length]);

  if (!data || !data.length) {
    return (
      <div className={styles.placeholder}>
        <FontAwesomeIcon icon={faMagic} size={"2x"} />
        <div className={styles.text}>
          <div>Fill out the form on the left to see</div>
          <div>your content show up here.</div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {_.map(data, (d) => (
        <GeneratedContentItem
          data={d}
          key={d.id || ""}
          actions={actions}
          openContentEditModal={() => handleItemClick(d.id)}
          openFlagModal={() => handleFlagModalOpen(d.id)}
        />
      ))}
      {modalData.isOpen && (
        <GeneratedContentModal
          isOpen={modalData.isOpen}
          data={_.find(data, (d) => d.id === modalData.id)}
          onClose={() => setModalData({ isOpen: false })}
          actions={actions}
        />
      )}
      {flagModalData.isOpen && (
        <FlagItem
          isOpen={flagModalData.isOpen}
          data={_.find(data, (d) => d.id === flagModalData.id)}
          onClose={() => setFlagModalData({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default ContentTab;
