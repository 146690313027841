import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePagination } from "../../hooks/useContentHistory";
import styles from "./Pagination.module.scss";

interface Props {
  data: any[] | undefined;
}

const Pagination = (props: Props) => {
  const { data } = props;
  const {
    onNextClick,
    onPrevClick,
    resetPagination,
    paginationData,
  } = usePagination();
  const history = useHistory();
  useEffect(() => {
    return history.listen(resetPagination);
  }, []);
  return (
    <div className={styles.pagination}>
      <button
        onClick={onPrevClick}
        disabled={paginationData.pageNo === 0}
        className={styles.button}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <span>{paginationData.pageNo + 1}</span>
      <button
        onClick={onNextClick}
        disabled={!Array.isArray(data) || data.length < 50}
        className={styles.button}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default Pagination;
