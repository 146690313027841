import { useRecoilState, RecoilState, SetterOrUpdater } from "recoil";
import { ContentResponse } from "@gpt3/types";
import { useCallback, useState } from "react";
import { client } from "../api";
import { SWRResponse } from "swr";
import _, { debounce } from "lodash";
import { Mutator } from "swr/dist/types";
import { applyPatches, enablePatches, produceWithPatches } from "immer";
import { useTags } from "./useTags";
import { useFilters } from "./useContentHistory";
import toast from "react-hot-toast";
enablePatches();

function makeOptimisticUpdate<T>(
  currentState: T,
  invalidateCache: (newState: T, shouldRevalidate?: boolean) => void
) {
  return async (
    patchLocal: (draft: T) => void,
    patchServer: () => Promise<void>
  ) => {
    // eslint-disable-next-line
    const [newState, _, rollbackPatches] = produceWithPatches<any>(
      currentState,
      patchLocal
    ) as any;
    invalidateCache(newState, false);
    try {
      await patchServer();
    } catch (err) {
      const oldState = applyPatches<T>(newState, rollbackPatches);
      invalidateCache(oldState, false);
    }
  };
}

const toggleFavouriteServer = async (content: ContentResponse) => {
  try {
    await client.favouriteContent(content.id);
  } catch (error) {}
};

const addTagServer = async (content: ContentResponse, tag: string) => {
  try {
    await client.addTagToContent(content.id, tag);
  } catch (error) {}
};

const removeTagServer = async (content: ContentResponse, tag: string) => {
  try {
    await client.removeTagFromContent(content.id, tag);
  } catch (error) {}
};

const editContentServer = async (id: ContentResponse["id"], text: string) => {
  try {
    await client.editContent(id, { text });
  } catch (error) {}
};

const revertContentServer = async (id: ContentResponse["id"]) => {
  try {
    await client.revertContentToOriginal(id);
  } catch (error) {}
};
const deleteContentServer = async (id: ContentResponse["id"]) => {
  try {
    await client.deleteContent(id);
  } catch (error) {}
};

const undoDeleteContentServer = async (id: ContentResponse["id"]) => {
  try {
    await client.undoDeleteContent(id);
  } catch (error) {}
};

export type Actions = {
  toggleFavourite: (id: ContentResponse["id"]) => void;
  addTag: (id: ContentResponse["id"], tag: string) => void;
  removeTag: (id: ContentResponse["id"], key: string | number) => void;
  editContent: (id: ContentResponse["id"], text: string) => void;
  revertContentToOriginal: (id: ContentResponse["id"]) => void;
  deleteContent: (id: ContentResponse["id"]) => void;
  undoDelete: (id: ContentResponse["id"]) => void;
};

export type Mutations =
  | SetterOrUpdater<ContentResponse[]>
  | SWRResponse<ContentResponse[], Error>["mutate"];

export type ContentWithActions = {
  data: ContentResponse[];
  mutation: Mutations;
};

const getElement = (data: ContentResponse[], id) =>
  _.findIndex(data, (d) => d.id === id);

function useContentMutations(
  data: ContentResponse[],
  mutation:
    | SetterOrUpdater<ContentResponse[]>
    | SWRResponse<ContentResponse[], Error>["mutate"]
): Actions;
function useContentMutations(data: ContentResponse[], mutation: any): Actions {
  const mutateContent = makeOptimisticUpdate(data, mutation);
  const { mutate: mutateTags } = useTags();
  const toggleFavourite = async (id: ContentResponse["id"]) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        draft[elemIndex].isFavorite = !draft[elemIndex].isFavorite;
        if (draft[elemIndex].isFavorite) {
          toast.success("Added to Favorites");
        }else {
          toast.error("Removed from Favorites");
        }
      },
      () => toggleFavouriteServer(data[elemIndex])
    );
  };

  const addTag = (id: ContentResponse["id"], tag) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        const newTags = _.uniq([
          ...(draft[elemIndex].tags && draft[elemIndex].tags),
          tag,
        ]);
        draft[elemIndex].tags = newTags;
      },
      async () => {
        await addTagServer(data[elemIndex], tag);
        mutateTags();
      }
    );
  };

  const removeTag = (id: ContentResponse["id"], key) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        Array.isArray(draft[elemIndex].tags) &&
          draft[elemIndex].tags.splice(key, 1);
      },
      async () => {
        await removeTagServer(data[elemIndex], data[elemIndex].tags[key]);
        mutateTags();
      }
    );
  };

  const editContent = (id: ContentResponse["id"], text) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        draft[elemIndex].userEditedText = text;
      },
      () => editContentServer(id, text)
    );
  };
  const revertContentToOriginal = (id: ContentResponse["id"]) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        draft[elemIndex].userEditedText = draft[elemIndex].openAIText;
      },
      () => revertContentServer(id)
    );
  };
  const deleteContent = (id: ContentResponse["id"]) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        draft.splice(elemIndex, 1);
      },
      () => deleteContentServer(id)
    );
  };
  const undoDelete = (id: ContentResponse["id"]) => {
    const elemIndex = getElement(data, id);
    mutateContent(
      (draft) => {
        draft.splice(elemIndex, 1);
      },
      () => undoDeleteContentServer(id)
    );
  };

  return {
    toggleFavourite,
    addTag,
    removeTag,
    editContent,
    revertContentToOriginal,
    deleteContent,
    undoDelete,
  };
}

const useDeleteContent = (content: ContentResponse, actions: Actions) => {
  const filters = useFilters();
  const [isDeleted, setDeleted] = useState(filters.deleted);
  const [isChanged, setIsChanged] = useState(false);
  const handleDelete = () => {
    if (isChanged) {
      if (isDeleted) {
        actions.deleteContent(content.id);
      } else {
        actions.undoDelete(content.id);
      }
    }
  };
  const onDeleteClick = () => {
    setIsChanged(!isChanged);
    setDeleted(true);
  };
  const onUndoClick = () => {
    setIsChanged(!isChanged);
    setDeleted(false);
  };
  return {
    isDeleted,
    onDeleteClick,
    onUndoClick,
    handleDelete,
  };
};

export { useContentMutations, useDeleteContent };
