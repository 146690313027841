import React, { useEffect, useMemo, useState } from "react";
import cx from "clsx";
import { forwardRef } from "react";
import styles from "./Field.module.scss";

const Input: React.ForwardRefExoticComponent<
  React.InputHTMLAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { autoComplete = "off", ...rest } = props;
    return <input ref={ref} autoComplete={autoComplete} {...rest} />;
  }
);

const TextArea: React.ForwardRefExoticComponent<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
>((props, ref) => {
  const { autoComplete = "off", ...rest } = props;
  return <textarea ref={ref} autoComplete={"off"} rows={4} {...rest} />;
});

type Props = {
  fieldType?: "input" | "textarea";
  id?: string;
  label: string;
  containerClassName?: string;
  maxLength: number;
  required?: boolean;
} & any;

const Field: React.ForwardRefExoticComponent<Props> = forwardRef<any, Props>(
  (props, ref) => {
    const {
      fieldType,
      id,
      label = "",
      type = "text",
      className,
      maxLength = 80,
      onChange,
      containerClassName,
      required,
      defaultValue,
      ...rest
    } = props;
    const [count, setCount] = useState(props?.defaultValue?.length || 0);
    const handleOnChange = (e) => {
      // FOr handling form onChnage
      onChange && onChange(e);
      setCount(e.target.value.length);
    };
    const Comp = useMemo(() => {
      switch (fieldType) {
        case "input":
          return Input;
        case "textarea":
          return TextArea;
        default:
          return Input;
      }
    }, [fieldType]);
    return (
      <div className={containerClassName}>
        <div className={styles.labelContainer}>
          <label htmlFor={id}>
            {label}
            {required && <sup>*</sup>}
          </label>
          <span className={styles.count}>
            {count}/{maxLength}
          </span>
        </div>

        <Comp
          ref={ref}
          onChange={handleOnChange}
          className={cx(styles.field, className)}
          required={required}
          maxLength={maxLength}
          {...rest}
        />
      </div>
    );
  }
);

export { Input, TextArea };

export default Field;
