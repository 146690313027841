import useSWR from 'swr';
import axios from 'axios';
import { get } from 'lodash';
import { client } from '../api';
import { getCurrentUser } from '../api/auth';
import { useAccessToken } from '.';
import { useEffect, useState } from 'react';
import { User } from '@gpt3/types';
import { atom, useRecoilState } from 'recoil';

type Session = {
  isAuthenticating: boolean;
  profile: User | undefined;
  error?: any;
};

const session = atom<Session>({
  key: 'session',
  default: {
    isAuthenticating: true,
    profile: undefined,
  },
});

const useAuthState = () => {
  const accessToken = useAccessToken();
  const [state, setState] = useRecoilState(session);
  const { isAuthenticating, profile, error } = state;
  const isAuthenticated = !!profile;
  useEffect(() => {
    const fetchUser = async () => {
      if (!isAuthenticating) {
        return;
      }

      try {
        const payload = await getCurrentUser();
        setState({
          isAuthenticating: false,
          profile: payload?.data,
        });
      } catch (error) {
        setState({
          isAuthenticating: false,
          profile: undefined,
          error,
        });
      }
    };
    fetchUser();
  }, [accessToken, isAuthenticating]);

  return {
    isAuthenticated,
    profile,
    isAuthenticating,
    error,
  };
};

export default useAuthState;
