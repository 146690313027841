import { Platform } from "@gpt3/types";
import React from "react";
import Pagination from "../../atoms/Pagination/Pagination";
import { useFilteredHistory } from "../../hooks";
import { PlatformContext } from "../../hooks/usePlatformContext";
import { SearchBar } from "../../molecules";
import { ContentCardGroup } from "../../molecules/ContentCardGroup/ContentCardGroup";
import styles from "./Content.module.scss";
interface Props {
  title?: string;
  searchBarClassname?: string;
}

const Content: React.FC<Props> = (props: Props) => {
  const { data, error, mutate } = useFilteredHistory();
  const { title = "AI Output", searchBarClassname } = props;
  return (
    <div className={styles.contentPage}>
      <SearchBar
        placeholder={"Search Input"}
        containerClassName={searchBarClassname}
      />
      <div className={styles.title}>{title}</div>
      <ContentCardGroup data={data} mutation={mutate} error={error} />
      <div className={styles.pagination}>
        <Pagination data={data} />
      </div>
    </div>
  );
};

const ContentWithPlatform: React.FC<Props & { platform?: Platform }> = (
  props
) => {
  const { platform, ...rest } = props;
  return (
    <PlatformContext.Provider value={platform}>
      <Content {...rest} />
    </PlatformContext.Provider>
  );
};
export default ContentWithPlatform;
