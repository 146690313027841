import React from "react";
import { TemplateResponse } from "@gpt3/types";
import TemplateImage from "../TemplateImage/TemplateImage";
import styles from "./TemplateCard.module.scss";

interface Props {
  data: TemplateResponse;
  onClick?: (template: TemplateResponse) => void;
}

const TemplateCard = (props: Props) => {
  const { data, onClick } = props;
  return (
    <button
      id={data.id}
      className={styles.templateCard}
      onClick={() => onClick(data)}
    >
      <div className={styles.title}>{data.title}</div>
      <TemplateImage templateId={data.id} containerClassname={styles.image} />
      <div className={styles.desc}>{data.description}</div>
    </button>
  );
};

export default TemplateCard;
