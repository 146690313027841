import React from 'react';
import cx from 'clsx';
import { ContentResponse } from '@gpt3/types';
import TimeAgo from "react-timeago";
import { getTextToShow } from "../../utils";
import toast from "react-hot-toast";
import { Actions } from "../../hooks/useContentActions";
import TagsInput from "../../atoms/TagsInput/TagsInput";
import styles from "./GeneratedContentItem.module.scss";
import { CopyButton, FavouriteButton } from "../Buttons";
import FlagButton from "../Buttons/FlagButton";

type Props = {
  data: ContentResponse;
  actions: Actions;
  openContentEditModal: () => void;
  openFlagModal: () => void;
};

const GeneratedContentItem: React.FC<Props> = (props) => {
  const { data, actions, openContentEditModal, openFlagModal } = props;
  const { text, isEdited } = getTextToShow(data);
  const { toggleFavourite, addTag, removeTag } = actions;
  return (
    <div className={cx(styles.item, { [styles.isNew]: data.isNew })}>
      <div className={styles.textContainer}>
        <pre className={styles.text} onClick={openContentEditModal}>
          {text?.trim()}
        </pre>
        <div className={styles.actionGroup}>
          <FavouriteButton
            data={data}
            onClick={(data, e) => {
              e.stopPropagation();
              toggleFavourite(data.id);
            }}
          />
          <CopyButton
            text={text}
            onCopy={() => toast.success("Copied")}
          ></CopyButton>
          <FlagButton data={data} onClick={openFlagModal} />
          {/* <div className="flex justify-end items-center flex-grow">
          <ActionButton label="Flag Content">
            <FontAwesomeIcon {...props} icon={faFlag} />
          </ActionButton>
        </div> */}
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.lowerContainer}
      >
        <TagsInput
          addTag={(t) => addTag(data.id, t)}
          removeTag={(key) => removeTag(data.id, key)}
          tags={data.tags}
        />
        <span className={styles.source}>AI Generated</span>
      </div>
    </div>
  );
};

export default GeneratedContentItem;
