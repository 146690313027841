import React, { useContext, useMemo } from "react";
import useSWR from "swr";
import { client } from "../api";
import _ from "lodash";
import { TemplateResponse } from "@gpt3/types";
import { useSearchState } from "./useSearch";
import { useParams } from "react-router-dom";
import { PlatformContext, usePlatformContext } from "./usePlatformContext";

const useTemplates = () => {
  const platform = useContext(PlatformContext);
  const params = {
    ...(platform && { platform }),
  };
  return useSWR("/templates", () => client.getTemplates(params));
};

const useTemplate = () => {
  const { data, error } = useTemplates();
  const params: any = useParams();
  return useMemo(() => {
    const t = _.find(data, (d) => d.id == params?.id);
    return { data: t, error };
  }, [data, params]);
};

const useTemplatesWithSearch = () => {
  const { text: search } = useSearchState();
  const { data: templates, error } = useTemplates();
  if (!search) {
    return { data: templates, error };
  }

  const result = _.filter(templates, (t) =>
    _.includes(Object.values(t).join(" ").toLowerCase(), search.toLowerCase())
  );
  return { data: result as TemplateResponse[], error };
};

const useTemplatesGroupByCategory = () => {
  const { data, error } = useTemplatesWithSearch();
  if (!data || error) {
    return {
      error,
      data: {},
    };
  }
  const result = _.groupBy(data, "category");
  return {
    data: result,
    error,
  };
};
export {
  useTemplate,
  useTemplates,
  useTemplatesWithSearch,
  useTemplatesGroupByCategory,
};
