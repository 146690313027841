import { TemplateResponse } from "@gpt3/types";
import { useCallback } from "react";
import { useContentHistory } from "./useContentHistory";
import { useOutput } from "./useOutput";

const useClearResult = (template: TemplateResponse) => {
  const { mutate } = useContentHistory(template?.id);
  const [output, setOutput] = useOutput();
  return useCallback(() => {
    mutate();
    setOutput([]);
  }, [template]);
};
export { useClearResult };
