import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentResponse } from "@gpt3/types";
import _ from "lodash";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import { useTemplate, useTemplates } from "../../hooks";
import {
  Actions,
  ContentWithActions,
  useDeleteContent,
} from "../../hooks/useContentActions";
import { getTextToShow } from "../../utils";
import { CopyButton, FavouriteButton } from "../Buttons";
import styles from "./ContentCard.module.scss";

type Props = {
  data: ContentResponse;
  actions: Actions;
  onClick?: () => void;
};

const ContentCard = (props: Props) => {
  const { data, actions, onClick } = props;
  const { data: templates } = useTemplates();
  const template = useMemo(() => {
    return _.find(templates, (t) => t.id === data.templateId);
  }, [templates]);
  const { text } = getTextToShow(data);
  const { toggleFavourite } = actions;
  return (
    <div className={styles.contentCard}>
      <div className={styles.topBar}>
        <button className={styles.titleButton}>{template?.title}</button>
        <div className={styles.actionGroup}>
          <FavouriteButton data={data} onClick={(t) => toggleFavourite(t.id)} />
          <CopyButton
            text={text}
            onCopy={() => toast.success("Copied")}
          ></CopyButton>
        </div>
      </div>
      <pre className={styles.text} onClick={onClick}>
        {text?.trim()}
      </pre>
    </div>
  );
};

export default ContentCard;
