import { ContentResponse } from "@gpt3/types";
import { atom, useRecoilState } from "recoil";

const EditContentModalData = atom<{
  isOpen: boolean;
  id?: ContentResponse["id"];
}>({
  key: "EditContentModalData",
  default: {
    isOpen: false,
  },
});

const useEditModalState = () => useRecoilState(EditContentModalData);

export { useEditModalState };
