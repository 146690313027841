import { useTemplate, useTemplates } from "../../hooks/useTemplates";
import React, { useState } from "react";
import { TemplateResponse } from "@gpt3/types";
import _ from "lodash";
import { useSelect } from "downshift";
import cx from "clsx";
import { HTMLAttributes } from "react";
import TemplateImage from "../TemplateImage/TemplateImage";
import Loader from "../../atoms/Loader/Loader";
import styles from "./TemplateSelector.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
  activeTemplate: TemplateResponse;
  setTemplate: (TemplateResponse) => void;
  containerClassname?: HTMLAttributes<HTMLDivElement>["className"];
}

type ListItemProps = {
  data?: TemplateResponse;
  showDropDownIcon?: boolean;
  isOpen?: boolean;
};

const ListItem: React.FC<ListItemProps> = (props) => {
  const { data, showDropDownIcon, isOpen } = props;
  if (!data) {
    return <></>;
  }
  return (
    <div className={styles.listItem}>
      <TemplateImage
        imageUrl={data.imageUrl}
        templateId={data.id}
        size={"small"}
      />
      <div className={styles.textContainer}>
        <div className={styles.title}>{data.title}</div>
        <div className={styles.desc}>{data.description}</div>
      </div>
      {showDropDownIcon &&
        (isOpen ? (
          <FontAwesomeIcon icon={faAngleUp} className={styles.arrow} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className={styles.arrow} />
        ))}
    </div>
  );
};

const TemplateSelectorStateLess: React.FC<Props> = (props) => {
  const { data: items } = useTemplates();
  const { activeTemplate, setTemplate, containerClassname } = props;
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<TemplateResponse>({
    items,
    defaultSelectedItem: activeTemplate,
    onSelectedItemChange: ({ selectedItem }) => {
      setTemplate(selectedItem);
    },
  });
  return (
    <div className={cx(styles.templateSelector, containerClassname)}>
      <div className={styles.dropdownContainer}>
        <button
          type="button"
          {...getToggleButtonProps()}
          className={cx(styles.button, {
            [styles.isOpen]: isOpen,
          })}
        >
          <ListItem data={selectedItem} showDropDownIcon isOpen={isOpen} />
        </button>
        <ul
          {...getMenuProps()}
          className={cx({
            [styles.list]: isOpen,
          })}
        >
          {isOpen &&
            _.map(items, (item, index) => (
              <li
                className={cx(styles.item)}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                <ListItem data={item} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

type TemplateSelectorProps = {
  setTemplate: (t: TemplateResponse) => void;
  containerClassname?: HTMLAttributes<HTMLDivElement>["className"];
};

const TemplateSelector: React.FC<TemplateSelectorProps> = (props) => {
  const { setTemplate, containerClassname } = props;
  const { data: template, error } = useTemplate();
  if (!template || error) {
    return <Loader />;
  }
  return (
    <TemplateSelectorStateLess
      setTemplate={setTemplate}
      activeTemplate={template}
      containerClassname={containerClassname}
      key={template?.id}
    />
  );
};

export { TemplateSelectorStateLess };
export default TemplateSelector;
