import { ContentResponse } from "@gpt3/types";
import { atom, useRecoilState } from "recoil";

const FlagContentModalData = atom<{
  isOpen: boolean;
  id?: ContentResponse["id"];
}>({
  key: "FlagContentModalData",
  default: {
    isOpen: false,
  },
});

const useFlagModalState = () => useRecoilState(FlagContentModalData);

export { useFlagModalState };
