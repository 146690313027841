import React from "react";
import { Tab } from "@headlessui/react";
import Button from "../../atoms/Button";
import { Variant } from "../../atoms/Button/Button";

import { useOutput } from "../../hooks/useOutput";
import { useContentHistory } from "../../hooks/useContentHistory";
import { useClearResult } from "../../hooks/useClearResult";
import ContentTab from "../ContentTab/ContentTab";
import { useTemplate } from "../../hooks/useTemplates";
import Loader from "../../atoms/Loader/Loader";
import tabStyles from "../../atoms/Tabs/Tabs.module.scss";
import clsx from "clsx";
import _ from "lodash";
import styles from "./ContentTabGroup.module.scss";

interface Props {
  scrollToTop: () => void;
}

const ContentTabGroup = (props: Props) => {
  const [output, setOutput] = useOutput();
  const { data: template, error } = useTemplate();
  const { data: contentHistory, mutate } = useContentHistory(template?.id);
  const clearResult = useClearResult(template);
  const tabData = [
    {
      name: "Result",
      data: output,
      mutation: setOutput,
    },
    {
      name: "History",
      data: contentHistory,
      mutation: mutate,
    },
  ];

  if (!template || error || !contentHistory) {
    return <Loader />;
  }

  return (
    <Tab.Group key={template.id}>
      <Tab.List className={clsx(tabStyles.list, styles.tabs)}>
        {_.map(tabData, (t, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              clsx(tabStyles.tab, { [tabStyles.selected]: selected })
            }
          >
            {t.name}
          </Tab>
        ))}
        <Button
          variant={Variant.CANCEL}
          className={styles.cancelButton}
          onClick={clearResult}
        >
          Clear Results
        </Button>
      </Tab.List>

      <Tab.Panels>
        {_.map(tabData, (t, index) => (
          <Tab.Panel key={index}>
            <ContentTab
              data={t.data}
              mutation={t.mutation}
              scrollToTop={props.scrollToTop}
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default ContentTabGroup;
