import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Platform, TemplateResponse } from "@gpt3/types";
import {
  useTemplatesGroupByCategory,
  useTemplatesWithSearch,
} from "../../hooks/useTemplates";
import Loader from "../../atoms/Loader/Loader";
import TemplateGroup from "../../molecules/TemplateGroup/TemplateGroup";
import styles from "./TemplateDashboard.module.scss";
import { PlatformContext } from "../../hooks/usePlatformContext";
import _ from "lodash";

type Props = {
  title?: string;
  onTemplateClick?: (template: TemplateResponse) => void;
  containerClassName?: HTMLDivElement["className"];
  platform?: Platform;
};

const Templates: React.FC<Props> = (props) => {
  const { title = "Templates", onTemplateClick, containerClassName } = props;
  const { data, error } = useTemplatesWithSearch();
  if (!data || error) {
    return <Loader />;
  }
  return (
    <div className={clsx(styles.dashboard, containerClassName)}>
      <div className={styles.title}>{title}</div>
      <TemplateGroup data={data} onTemplateCardClick={onTemplateClick} />
    </div>
  );
};

type TemplatesByCategoryProps = {
  onTemplateClick?: (template: TemplateResponse) => void;
  containerClassName?: HTMLDivElement["className"];
  platform?: Platform;
};

const TemplatesByCategory: React.FC<TemplatesByCategoryProps> = (props) => {
  const { onTemplateClick, containerClassName } = props;
  const { data, error } = useTemplatesGroupByCategory();
  if (!data || error) {
    return <Loader />;
  }
  return (
    <div className={clsx(styles.dashboard, containerClassName)}>
      {_.map(data, (groupedTemplate, categoryName) => {
        return (
          <div className={styles.groupContainer}>
            <div className={styles.title}>{categoryName || "Default"}</div>
            <TemplateGroup
              data={groupedTemplate}
              onTemplateCardClick={onTemplateClick}
            />
          </div>
        );
      })}
    </div>
  );
};

const TemplateWithPlatform: React.FC<Props> = (props) => {
  const { platform, ...rest } = props;
  return (
    <PlatformContext.Provider value={platform}>
      <Templates {...rest} />
    </PlatformContext.Provider>
  );
};

const TemplateByCategoryWithPlatform: React.FC<Props> = (props) => {
  const { platform, ...rest } = props;
  return (
    <PlatformContext.Provider value={platform}>
      <TemplatesByCategory {...rest} />
    </PlatformContext.Provider>
  );
};

export { TemplatesByCategory, TemplateByCategoryWithPlatform };
export default TemplateWithPlatform;
