import _ from "lodash";
import { OpenAICompletionResponse, User } from "./types";

/**
 * Check whether a given value is an array where
 * each member is of a specified type
 *
 * @param arr - array to check
 * @param check - type guard to use when evaluating each item
 */
export function isTypedArray<T>(
  arr: unknown,
  check: (x: any) => x is T
): arr is T[] {
  if (!Array.isArray(arr)) return false;
  if (arr.some((item) => !check(item))) return false;
  return true;
}

/**
 * Check whether a given value is a user object.
 * @param arg - value to check
 */
export function isUser(arg: any): arg is User {
  return (
    _.isObjectLike(arg) &&
    _.isString(arg.id) &&
    // _.isString(arg.email) &&
    // (_.isNil(arg.firstName) || _.isString(arg.firstName)) &&
    // (_.isNil(arg.lastName) || _.isString(arg.lastName)) &&
    // _.isString(arg.role) &&
    // isTypedArray(arg.permissions, _.isString) &&
    _.isString(arg.tenant)
  );
}

/**
 * Check whether a given value is a valid OpenAI response.
 * @param arg value to check
 */
export function isOpenAIResponse(arg: any): arg is OpenAICompletionResponse {
  const isValidOpenAIChoice = (
    choice: any
  ): choice is {
    text: string;
  } => {
    return _.isObjectLike(choice) && _.isString(choice.text);
  };

  return (
    _.isObjectLike(arg) &&
    // _.isString(arg.id) &&
    // _.isString(arg.model) &&
    _.isNumber(arg.created) &&
    _.isString(arg.object) &&
    isTypedArray(arg.choices, isValidOpenAIChoice)
  );
}
