import { matchPath, useLocation } from "react-router-dom";

const useNavItemActive = (url: string) => {
  const location = useLocation();
  if (!location.pathname) {
    return url === "/";
  }
  if (url === "/home" && location.pathname === "/") {
    return true;
  }
  return !!matchPath(location.pathname, url);
};

export default useNavItemActive;
