import clsx from "clsx";
import React, { HTMLAttributes } from "react";
import { TemplateType } from "@gpt3/types";
import DefaultImage from "../../assets/defaultTemplate.svg";
import styles from "./TemplateImage.module.scss";
import AIDAImage from "./AIDA.svg";
import PASImage from "./PAS.svg";
import PostImage from "./Post.svg";
import GoogleImage from "./Google.svg";
import FbImage from "./Fb.svg";
import MailImage from "./mail.svg";
import PainPointImage from "./PainPoint.svg";
import ValuePropImage from "./ValueProp.svg";
import LinkedInImage from "./Linkedin.svg";
import { TemplateResponse } from "@gpt3/types";
interface Props {
  imageUrl?: string;
  templateId: TemplateResponse["id"];
  containerClassname?: HTMLAttributes<HTMLDivElement>["className"];
  className?: HTMLAttributes<HTMLDivElement>["className"];
  size?: "small";
}
const TemplateImageMap: Partial<Record<TemplateType, any>> = {
  [TemplateType.AIDA]: AIDAImage,
  [TemplateType.PAS]: PASImage,
  [TemplateType.BLOG_POST_INTRO_PARA]: PostImage,
  [TemplateType.BLOG_POST_TOPIC_IDEAS]: PostImage,
  [TemplateType.BLOG_POST_CONCLUSION]: PostImage,
  [TemplateType.BLOG_POST_OUTLINE]: PostImage,
  [TemplateType.PERFECT_HEADLINE]: PostImage,
  [TemplateType.FACEBOOK_AD_HEADLINE]: FbImage,
  [TemplateType.FACEBOOK_AD_PRIMARY_TEXT]: FbImage,
  [TemplateType.GOOGLE_AD_HEADLINE]: GoogleImage,
  [TemplateType.GOOGLE_AD_DESCRIPTION]: GoogleImage,
  [TemplateType.COLD_EMAIL]: MailImage,
  [TemplateType.FOLLOW_UP_SNIPPETS]: MailImage,
  [TemplateType.SUBJECT_LINE]: MailImage,
  [TemplateType.VALUE_PROPS]: ValuePropImage,
  [TemplateType.PAIN_POINTS]: PainPointImage,
  [TemplateType.INTRO_LINES]: MailImage,
  [TemplateType.LINKEDIN_BLOG_POST_INTRO_PARA]: LinkedInImage,
  [TemplateType.LINKEDIN_BLOG_POST_TOPIC_IDEAS]: LinkedInImage,
  [TemplateType.LINKEDIN_BLOG_POST_CONCLUSION]: LinkedInImage,
  [TemplateType.LINKEDIN_BLOG_POST_OUTLINE]: LinkedInImage,
  [TemplateType.LINKEDIN_COLD_EMAIL]: LinkedInImage,
  [TemplateType.LINKEDIN_CONNECTION_REQUEST]: LinkedInImage,
  [TemplateType.BREAKUP_EMAILS]: MailImage,
  [TemplateType.FOLLOW_UP_EMAILS]: MailImage,
  [TemplateType.REFERRAL_EMAILS]: MailImage,
  [TemplateType.MULTIPLE_CHOICE_EMAILS]: MailImage,
};
export const getImage = (templateId: TemplateResponse["id"]) => {
  if (TemplateImageMap[templateId]) {
    return TemplateImageMap[templateId];
  }
  return MailImage;
};

const TemplateImage: React.FC<Props> = (props) => {
  const { containerClassname, imageUrl, templateId, size } = props;
  return (
    <div className={clsx(containerClassname, styles.imageContainer)}>
      <img
        src={getImage(templateId)}
        className={clsx(styles.image, { [styles.small]: size === "small" })}
      />
    </div>
  );
};

export default TemplateImage;
