import { ContentResponse } from "@gpt3/types";
const getTextToShow = (content?: ContentResponse) => {
  if (!content) {
    return {
      isEdited: undefined,
      text: undefined,
    };
  }
  const { userEditedText, openAIText } = content;
  const isEdited = userEditedText && userEditedText !== openAIText;
  return {
    isEdited,
    text: isEdited ? userEditedText : openAIText,
  };
};

export { getTextToShow };
