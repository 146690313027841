import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBook,
  faBookOpen,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faCopy,
  faDollarSign,
  faEllipsisH,
  faFlag,
  faLock,
  faMagic,
  faMinusCircle,
  faPencilAlt,
  faPlusCircle,
  faSearch,
  faSpinner,
  faStar,
  faTag,
  faTimes,
  faTrash,
  faUndo,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import cx from "clsx";
import React, { MouseEventHandler } from "react";
import styles from "./ActionButton.module.scss";

library.add(
  faCheckSquare,
  faDollarSign,
  faChartLine,
  faBookOpen,
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faUser,
  faLock,
  faTrash,
  faPencilAlt,
  faTimes,
  faUsers,
  faEllipsisH,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faBook,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faStar,
  faFlag,
  faSpinner,
  faCheckCircle,
  faCopy,
  faAngleRight,
  faTag,
  faMagic,
  faUndo
);

interface Props {
  className?: string;
  onClick?: MouseEventHandler;
  label?: string;
}

const ActionButton: React.FC<Props> = (props) => {
  const { className, onClick, label, children } = props;
  return (
    <button className={cx(styles.actionButton, className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default ActionButton;
