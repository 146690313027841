import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from ".";
import { isTokenExpired } from "../api/auth";
import useLocalStorage from "./useLocalStorage";

const useAccessToken = () => {
  const query = useQuery();
  const history = useHistory();
  const getAccessToken = () => {
    return localStorage.getItem("access_token");
  };
  const [accessToken, setAccessToken] = useState(getAccessToken());
  const [tokenFromLocalStorage, setToken] = useLocalStorage(
    "access_token",
    accessToken || ""
  );

  useEffect(() => {
    const token = query.get("token") || tokenFromLocalStorage;
    if (query.get("token")) {
      query.delete("token");
      setToken(token);
      history.replace({
        ...(history.location.state as any),
        search: query.toString(),
      });
    }
    setAccessToken(token);
  }, [query, tokenFromLocalStorage]);

  if (isTokenExpired(accessToken)) {
    window.localStorage.removeItem("access_token");
    return null;
  }

  return accessToken;
};

export default useAccessToken;
