import _ from 'lodash';
import React, { useRef } from "react";
import TemplateForm from "../../molecules/TemplateForm/TemplateForm";
import { Platform, TemplateResponse } from "@gpt3/types";
import TemplateSelector from "../../molecules/TemplateSelector/TemplateSelector";
import ContentTabGroup from "../../molecules/ContentTabGroup/ContentTabGroup";
import styles from "./Template.module.scss";
import { useEffect } from "react";
import { useClearResult, useTemplate } from "../../hooks";
import { PlatformContext } from "../../hooks/usePlatformContext";

interface Props {
  setTemplate: (t: TemplateResponse) => void;
  platform?: Platform;
}

const Template: React.FC<Props> = (props) => {
  const { setTemplate } = props;
  const { data: template, error } = useTemplate();
  const clearResult = useClearResult(template);
  useEffect(() => {
    clearResult();
  }, [template]);
  const ref = useRef(null);
  const scrollToTop = () => {
    if (ref?.current) {
      ref.current.scrollTo({ behavior: "smooth", top: 0 });
    }
  };

  return (
    <div className={styles.templatePage}>
      <div className={styles.leftContainer}>
        <div className={styles.fieldsContainer}>
          <TemplateSelector
            setTemplate={setTemplate}
            containerClassname="mt-4"
          />
          <TemplateForm />
        </div>
      </div>
      <div className={styles.rightContainer} ref={ref}>
        <ContentTabGroup scrollToTop={scrollToTop} />
      </div>
    </div>
  );
};

const TemplateWithPlatform: React.FC<Props> = (props) => {
  const { platform, ...rest } = props;
  return (
    <PlatformContext.Provider value={platform}>
      <Template {...rest} />
    </PlatformContext.Provider>
  );
};

export default TemplateWithPlatform;