import { useMemo } from "react";
import _ from "lodash";
import { matchPath, useLocation } from "react-router-dom";

export default function usePageMeta(routes) {
  const location = useLocation();
  return useMemo(() => {
    const matches = _.flatMap(routes, (r) =>
      r._children ? r._children : r
    ).filter((r) => matchPath(location.pathname, r?.url));
    const result = matches[matches.length - 1];
    return {
      title: result?.title,
    };
  }, [location]);
}
