import { ApiClient } from "@gpt3/api-client";
import {
  setTokens,
  removeTokens,
  retryHandler,
  fetchTokensWithCode,
  getCurrentUser,
  AxiosAuthInstance,
  gpt3AuthRetryHandler,
  getAccessToken,
} from "./auth";

const client = new ApiClient({
  baseUrl: process.env.REACT_APP_GPT3_BASE_URL,
  getAccessToken,
});

export {
  client,
  setTokens,
  removeTokens,
  retryHandler,
  fetchTokensWithCode,
  getCurrentUser,
  AxiosAuthInstance,
  gpt3AuthRetryHandler,
  getAccessToken,
};
