import React, { HtmlHTMLAttributes } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentResponse, TemplateResponse } from "@gpt3/types";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  data?: ContentResponse;
  onCopy: () => void;
  text: string;
  buttonClassName?: React.HTMLAttributes<HTMLButtonElement>["className"];
}

const CopyButton: React.FC<Props> = (props: Props) => {
  const { data, onCopy, text, buttonClassName } = props;
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <ActionButton className={buttonClassName} label="Copy to Clipboard">
        <FontAwesomeIcon icon={faCopy} title="Copy to Clipboard" />
      </ActionButton>
    </CopyToClipboard>
  );
};

export default CopyButton;
