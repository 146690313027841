import { User } from '@gpt3/types';
import axios, { AxiosInstance } from 'axios';
import { get } from 'lodash';
import { mutate } from 'swr';
import { client } from '.';
import jwtDecode, { JwtPayload } from 'jwt-decode';

type Tokens = {
  refresh: string;
  access: string;
  expiresAt: number;
};
const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const isTokenExpired = (accessToken: string) => {
  if (!accessToken) {
    return true;
  }
  const payload = jwtDecode<{ exp: number; nbf: number }>(accessToken);
  const { exp, nbf } = payload;
  const millsecondsInSecond = 1000;
  const currentTimeInSeconds = Date.now() / millsecondsInSecond;
  const timeIsValid =
    (nbf === undefined || currentTimeInSeconds > nbf) &&
    (exp === undefined || currentTimeInSeconds < exp);

  return !timeIsValid;
};
const AxiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const setTokens = (tokens: Tokens) => {
  localStorage.setItem('access_token', tokens.access);
  localStorage.setItem('refresh_token', tokens.refresh);
  localStorage.setItem('token_expires_at', tokens.expiresAt.toString());
};

const removeTokens = () => {
  mutate('/api/auth');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token_expires_at');
};

const AuthBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

AxiosAuthInstance.interceptors.request.use(function (config) {
  const token = getAccessToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

AxiosAuthInstance.interceptors.response.use((response) => {
  return {
    ...response,
  };
}, retryHandler);

async function retryHandler(error) {
  const originalRequest = error.config;
  if ([403, 401].includes(get(error, 'response.status'))) {
    console.log(error.response);
    if (get(error, 'response.data.type') === 'no_account') {
      removeTokens();
      return Promise.reject({
        message: 'Please contact us to setup your account.',
      });
    }
    removeTokens();
    return Promise.reject({
      message: get(error, 'response.data.error'),
    });
  }
  return Promise.reject(error);
}

async function refreshTokens(error) {
  const originalRequest = error.config;
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const response = await AxiosAuthInstance.post(
        `${AuthBaseUrl}/tokens/refresh`,
        { token: refreshToken },
      );
      const data = response.data;
      if (data.access && data.refresh) {
        setTokens(data as any);
        originalRequest.headers.Authorization =
          'Bearer ' + (data as any).access;
        return AxiosAuthInstance(originalRequest);
      }
    } catch (e) {
      await removeTokens();
      return Promise.reject({
        message: 'Token expired. Please login again.',
      });
    }
  }
}

async function gpt3AuthRetryHandler(error) {
  if ([401].includes(get(error, 'response.status'))) {
    await removeTokens();
    return Promise.reject({
      message: get(error, 'response.data'),
    });
  }
}

const fetchTokensWithCode = async (code: string): Promise<void> => {
  const tokens: Tokens = await AxiosAuthInstance.post('/tokens/code', {
    code: code,
  });
  setTokens(tokens);
  window.location.href = '/';
};

const getCurrentUser = async (): Promise<{ data?: User } | undefined> => {
  if (!getAccessToken()) {
    return;
  }
  const result = await AxiosAuthInstance.get('/users/me');
  return result.data;
};

export {
  Tokens,
  setTokens,
  removeTokens,
  retryHandler,
  fetchTokensWithCode,
  getCurrentUser,
  getAccessToken,
  AxiosAuthInstance,
  gpt3AuthRetryHandler,
};
