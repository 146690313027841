import { TemplateResponse } from "@gpt3/types";
import React from "react";
import { usePlatformContext } from "../../hooks/usePlatformContext";
import TemplateCard from "../TemplateCard/TemplateCard";
import styles from "./TemplateGroup.module.scss";

interface Props {
  data?: TemplateResponse[];
  onTemplateCardClick: (template: TemplateResponse) => void;
}

const TemplateGroup: React.FC<Props> = (props: Props) => {
  const { data, onTemplateCardClick } = props;
  return (
    <div className={styles.templateGroup}>
      {Array.isArray(data) &&
        data.map((t) => (
          <TemplateCard key={t.id} data={t} onClick={onTemplateCardClick} />
        ))}
    </div>
  );
};

export default TemplateGroup;
