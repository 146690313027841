import React from "react";

import useSWR from "swr";
import { client } from "../api";

const useTags = () => {
  return useSWR("/tags", () => client.getTags());
};

export { useTags };
