import _ from "lodash";
import React from "react";
import { useEditModalState } from "../../hooks";
import {
  ContentWithActions,
  useContentMutations,
} from "../../hooks/useContentActions";
import ContentCard from "../ContentCard/ContentCard";
import GeneratedContentModal from "../GeneratedContentModal/GeneratedContentModal";
import styles from "./ContentCardGroup.module.scss";
type Props = ContentWithActions & { error: any };

const ContentCardGroupStateLess: React.FC<Props> = (props: Props) => {
  const { data, mutation } = props;
  const actions = useContentMutations(data, mutation);
  const [modalData, setModalData] = useEditModalState();
  const handleItemClick = (id) => {
    setModalData({ isOpen: true, id });
  };
  return (
    <>
      <div className={styles.group}>
        {_.map(data, (d) => (
          <ContentCard
            data={d}
            actions={actions}
            key={d.id}
            onClick={() => handleItemClick(d.id)}
          />
        ))}
      </div>
      {modalData.isOpen && (
        <GeneratedContentModal
          isOpen={modalData.isOpen}
          data={_.find(data, (d) => d.id === modalData.id)}
          onClose={() => setModalData({ isOpen: false })}
          actions={actions}
        ></GeneratedContentModal>
      )}
    </>
  );
};

const ContentCardGroup: React.FC<Props> = (props) => {
  const { data, error } = props;
  if (!data || error) {
    <></>;
  }
  return <ContentCardGroupStateLess {...props} />;
};
export { ContentCardGroup, ContentCardGroupStateLess };
