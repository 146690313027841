import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

const PARAM = "search-string";

export function useLocationState(paramName: string) {
  const { search, pathname } = useLocation();
  const history = useHistory();

  return useMemo(() => {
    const q = qs.parse(search);
    const val = q[paramName];
    const text: string = (Array.isArray(val) ? val[0] : val) || "";

    const setText = (value: string) => {
      history.replace({
        pathname,
        search: qs.stringify({
          ...qs.parse(search),
          [paramName]: value || undefined,
        }),
      });
    };

    return {
      text,
      onChange: (e: any) => {
        setText(e.target.value);
      },
      setText,
      resetText: () => setText(""),
    };
  }, [search, pathname, history, paramName]);
}

export function useSearchState() {
  return useLocationState(PARAM);
}
