import { library } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faCubes } from '@fortawesome/free-solid-svg-icons';
import {
  client,
  gpt3AuthRetryHandler,
  Loader,
  removeTokens,
  useAuthState,
} from '@gpt3/components';
import '@gpt3/components/dist/index.css';
import SignIn from 'containers/SignIn/SignIn';
import LogRocket from 'logrocket';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

library.add(faBolt, faCubes);

if (process.env.REACT_APP_LOGROCKET_KEY) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
}

client.setErrorHandler(gpt3AuthRetryHandler);

const View: any = React.lazy(() => import('./containers/Layout/Layout'));

function AuthGuard(props) {
  const { isAuthenticated, profile, isAuthenticating, error } = useAuthState();
  if (isAuthenticating) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (error) {
    removeTokens();
    return (
      <div className={'w-full h-full flex items-center justify-center'}>
        <a href={'https://unified.regie.ai'}>{'Please try to login again'}</a>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: { from: props.location },
        }}
      />
    );
  }
  return <View {...props} />;
}

function Routes() {
  const { profile } = useAuthState();

  const [isLRIdentifySet, setLRIdentify] = useState<boolean>(false);

  if (!!profile && !isLRIdentifySet && process.env.REACT_APP_LOGROCKET_KEY) {
    LogRocket.identify(profile.id, {
      name: profile.firstName,
      email: profile.email,
      role: profile.role,
      defaultProduct: profile.defaultProduct,
    });
    setLRIdentify(true);
  }

  return (
    <Switch>
      <Route exact path="/signin" render={() => <SignIn />} />

      <Route path="/" render={(props) => <AuthGuard {...props} />} />
    </Switch>
  );
}

function App() {
  return (
    <RecoilRoot>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
        }}
      >
        <Router>
          <React.Suspense fallback={<Loader />}>
            <Routes />
          </React.Suspense>
        </Router>
      </SWRConfig>
      <Toaster toastOptions={{ duration: 3000 }} />
    </RecoilRoot>
  );
}

export default App;
